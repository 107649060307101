const cryptoUtils = {
  async generateKey() {
    const key = await window.crypto.subtle.generateKey(
      { name: 'AES-GCM', length: 256 },
      true, // extractable
      ['encrypt', 'decrypt'],
    )
    // Export and store the key as Base64 in a cookie for simplicity
    const keyExported = await window.crypto.subtle.exportKey('raw', key)
    const keyBase64 = btoa(String.fromCharCode(...new Uint8Array(keyExported)))
    this.setCookie('yeKresu', keyBase64, 7) // Storing key in cookie for 7 days
    return key
  },

  async getKey() {
    let keyBase64 = this.getCookie('yeKresu')
    if (!keyBase64) {
      await this.generateKey()
      keyBase64 = this.getCookie('yeKresu')
    }
    const keyRaw = Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0))
    return window.crypto.subtle.importKey(
      'raw',
      keyRaw,
      'AES-GCM',
      true,
      ['encrypt', 'decrypt'],
    )
  },

  setCookie(name, value, days) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
      expires = `; expires=${ date.toUTCString()}`
    }
    document.cookie = `${name }=${ value || '' }${expires }; path=/; Secure; SameSite=Strict`
  },

  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  },

  async encryptString(plainText) {
    const key = await this.getKey()
    const iv = window.crypto.getRandomValues(new Uint8Array(12))
    const encoder = new TextEncoder()
    const encoded = encoder.encode(plainText)
    const encrypted = await window.crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      encoded,
    )
    const ivBase64 = btoa(String.fromCharCode(...iv))
    const encryptedBase64 = btoa(String.fromCharCode(...new Uint8Array(encrypted)))
    return { encrypted: encryptedBase64, iv: ivBase64 }
  },

  async decryptString(encryptedTextBase64, ivBase64) {
    const key = await this.getKey()
    const iv = Uint8Array.from(atob(ivBase64), c => c.charCodeAt(0))
    const encryptedText = Uint8Array.from(atob(encryptedTextBase64), c => c.charCodeAt(0))
    const decrypted = await window.crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      key,
      encryptedText,
    )
    const decoder = new TextDecoder()
    return decoder.decode(decrypted)
  },
}

export default cryptoUtils
