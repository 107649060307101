<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tally-sale-logo />
        <h2 class="brand-text text-primary ml-1">
          Tally Sale
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Welcome 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- username -->
              <b-form-group
                label="Username"
                label-for="login-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="login-username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="login-username"
                    placeholder="Username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>

                  <b-link :to="{name:'forgot-password'}">
                    <small>Forgot Password?</small>
                  </b-link>

                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="topyenoh">
                <b-form-input
                  id="topyenoh"
                  v-model="topyenoh"
                  name="topyenoh"
                />
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="remember"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="login"
              >
                Sign in
              </b-button>

              <b-card-text class="text-center mt-2">
                <span>Not Registered Yet? </span>
                <b-link :to="{name:'register'}">
                  <span>&nbsp;Sign Up</span>
                </b-link>
              </b-card-text>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TallySaleLogo from '@/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import cryptoUtils from '@/libs/crytoUtils'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    TallySaleLogo,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      topyenoh: '',
      remember: false,
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      errorMessage: '',
      required,
      decryptedMessage: '',
      encryptedData: null,
      iv: null,
      credential: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.rememberLogin()
  },
  methods: {
    async rememberCredential() {
      const c = btoa(JSON.stringify({
        u: this.username,
        p: this.password,
      }))
      const { encrypted, iv } = await cryptoUtils.encryptString(c)
      localStorage.setItem('ec', encrypted)
      localStorage.setItem('iv', iv)
    },
    async rememberLogin() {
      const ec = localStorage.getItem('ec')
      const iv = localStorage.getItem('iv')
      if (ec && iv) {
        const decryptedCredentials = await this.getDecryptedCredentials(ec, iv)

        await Promise.resolve(decryptedCredentials).then(resolvedResult => {
          useJwt.login({
            name: resolvedResult.u,
            password: resolvedResult.p,
          })
          .then(response => {
            this.authExecute(response)
          })
          .catch(error => {
            this.$refs.loginValidation.setErrors(error.response.data.message)
            this.errorMessage = `${error.response.data.message}`
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: this.errorMessage,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
        })
      }
    },
    async getDecryptedCredentials(ec, iv) {
      const dc = await cryptoUtils.decryptString(ec, iv) // Decrypt credentials
      const decodedCredentials = atob(dc) // Decode from Base64 to string
      return JSON.parse(decodedCredentials) // Parse and return the credentials
    },
    activateUser() {
      const { token } = this.$router.currentRoute.query
      if (token) {
        useJwt.activateUser({
          activate_user_token: token,
        })
        .then(response => {
          this.authExecute(response)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              text: 'Invalid token for activation, Please try again.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: 5000,
            },
          })
          this.$router.push({ name: 'login' })
        })
      }
    },
    authExecute(response) {
      const { userData, employeeData } = response.data
      useJwt.setToken(response.data.accessToken)
      localStorage.setItem('userData', JSON.stringify(userData))
      localStorage.setItem('employeeData', JSON.stringify(employeeData))

      const { branch } = employeeData
      const configData = [
        { name: 'business_name', value: branch.name },
        { name: 'business_description', value: branch.description },
        { name: 'business_phone', value: branch.phone },
        { name: 'business_website', value: '' },
        { name: 'business_email', value: branch.email },
        { name: 'business_address', value: branch.address },
        { name: 'business_logo', value: branch.logo },
      ]
      localStorage.setItem('configData', JSON.stringify(configData))
      this.$ability.update(userData.role.abilities)

      // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
      this.$router.replace(getHomeRouteForLoggedInUser(userData.role.name))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${employeeData.name || userData.name}`,
              autoHideDelay: '3000',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: `You have successfully logged in as ${userData.role.name}. Now you can start to explore!`,
            },
          })
        })
    },
    login() {
      if (this.topyenoh) {
        return
      }
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Logging In',
              autoHideDelay: '5000',
              text: 'Please wait a moment ...',
              icon: 'LogInIcon',
              variant: 'primary',
            },
          })

          useJwt.login({
            name: this.username,
            password: this.password,
            topyenoh: this.topyenoh,
          })
            .then(response => {
              if (this.remember) {
                this.rememberCredential()
              }
              this.authExecute(response)
            })
            .catch(error => {
              // this.$refs.loginValidation.setErrors(error.response.data.error)
              this.$refs.loginValidation.setErrors(error.response.data.message)
              this.errorMessage = `${error.response.data.message}`
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  text: this.errorMessage,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        } else {
          // do something
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
